<template>
  <section>
    <div class="case-creation__medication" style="margin: 0 0 15px">
      <div class="medication-card">
        <div class="case-creation__heading">
          <div class="title-with-icon">
            <span class="icon">
              <img
                src="@/assets/images/icons/icon-test-info.svg"
                alt="icon"
                class="img-fluid"
              />
            </span>
            <h3 class="title">Medications</h3>
          </div>
        </div>
        <div class="medication-content">
          <ul class="medication-block">
            <li>
              <p class="">(Note:- Add Medication One-by-one)</p>
            </li>
            <li>
              <span class="medicine-img">
                <img
                  src="@/assets/images/medicines.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
            </li>
            <li>
              <div class="medication-search-input-lg input-with-icon">
                <el-form @keyup.enter.prevent="addMedication()">
                  <el-form-item class="medication-form-item">
                    <el-select
                      v-model="patientMedicationFormData.value"
                      filterable
                      clearable
                      remote
                      :reserve-keyword="false"
                      placeholder="Search Medication"
                      :remote-method="getAutoCompleteMedicationsData"
                      :loading="medicationLoading"
                      :disabled="hasCases"
                      
                    >
                      <el-option
                        v-for="(
                          medication, index
                        ) in patientMedicationFormData.medicationsData"
                        :key="index"
                        :label="medication.drug"
                        :value="medication.drug"
                        :disabled="
                          patientMedicationFormData.options.indexOf(
                            medication.drug
                          ) != -1
                            ? true
                            : false
                        "
                      >
                        <span style="float: left">{{ medication.drug }}</span>
                        <span
                          style="
                            float: right;
                            color: #8492a6;
                            padding-left: 7px;
                          "
                          >{{ medication.is_child ? "Brand" : "" }}</span
                        >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>

                <span>
                  <img
                    src="@/assets/images/icons/search-icon-with-bg-red.svg"
                    alt="icon"
                  />
                </span>
              </div>
            </li>
            <li>
              <span
                ><el-button
                  class="btn radius-button"
                  @click="addMedication"
                  :disabled="!patientMedicationFormData.value"
                  :loading="medicationLoading"
                  >+ ADD</el-button
                >
                <!-- <el-button
                  class="btn radius-button radius-button-red"
                  @click="addMedicationModelPopup = true"
                  :disabled="hasCases"
                  >+ADD NEW</el-button
                > -->
              </span>
            </li>
          </ul>

          <div class="default-modal">
            <el-dialog
              width="25%"
              v-model="addMedicationModelPopup"
              @close="removeErrors"
              :show-close="true"
              :destroy-on-close="true"
            >
              <div class="dialog-header">
                <span class="header-part">
                  <img src="@/assets/images/icons/cases/pills.svg" alt="icon" />
                  Add Medication
                </span>
                <el-button
                  size="medium"
                  class="el-white"
                  type="info"
                  plain
                  @click="addMedicationModelPopup = false"
                >
                  <img
                    src="@/assets/images/icons/cases/close-btn.svg"
                    alt="icon"
                  />
                </el-button>
              </div>
              <div class="default-input pb-0">
                <div>
                  <el-input
                    placeholder="Enter medication"
                    v-model="formData.drug"
                    @input="formData.drug = formData.drug.toUpperCase()"
                    
                  >
                  </el-input>
                  <p
                    class="err"
                    v-if="
                      getMedicationValidationErrors &&
                      getMedicationValidationErrors.critical_error
                    "
                  >
                    {{ getMedicationValidationErrors.critical_error }}
                  </p>
                  <p
                    class="err"
                    v-if="
                      getMedicationValidationErrors &&
                      getMedicationValidationErrors.drug
                    "
                  >
                    {{ getMedicationValidationErrors.drug }}
                  </p>
                </div>

                <div class="dialog-footer">
                  <el-button
                    class="blue-save-button"
                    @click="createMedication"
                    :disabled="buttonLoading"
                  >
                    + Create Medication
                  </el-button>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <p
        class="err"
        v-if="
          getMedicationValidationErrors &&
          getMedicationValidationErrors.medication
        "
      >
        <FormError errorName="medication"> </FormError>
      </p>
      <div
        class="medication-list-block"
        v-if="
          patientMedicationFormData.options &&
          patientMedicationFormData.options.length
        "
      >
        <ul>
          <li
            v-for="(medication, index) in patientMedicationFormData.options"
            :key="index"
            class="text item"
          >
            <span>{{ medication }}</span>

            <el-button
              type="text"
              class="minus-icon"
              @click="removeMedication(medication)"
              :disabled="hasCases"
            >
              <span>
                <img
                  src="@/assets/images/minus-button.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

import { successNotification } from "@/helpers/notifications";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);

export default {
  props: ["caseType", "newCase", "medicationsData"],
  data() {
    return {
      patientMedicationFormData: {
        options: [],
        medications: [],
        medicationsData: [],
        groupOptions: [],
        value: "",
        list: [],
        loading: false,
      },
      formData: {
        drug: "",
        group: "",
        is_child: true,
      },
      medicationLoading: false,
      buttonLoading: false,

      addMedicationModelPopup: false,
    };
  },
  components: {
    FormError,
  },
  computed: {
    ...mapGetters("cases", [
      "getMedicationCreateStatus",
      "getAllMedications",
      "getMedicationValidationErrors",
    ]),
    ...mapGetters("orders", ["getCaseInformation"]),
    hasCases() {
      if (this.checkUser && !this.hasAllowedCaseType && !this.newCase) {
        return true;
      } else if (this.checkUser && this.hasAllowedCaseType && this.newCase) {
        return true;
      }
      return false;
    },
    checkUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type == "LAB_TECHNOLOGIST"
      ) {
        return true;
      }
      return false;
    },
    hasAllowedCaseType() {
      if (
        this.getAuthenticatedUser.allowed_case_types &&
        this.getAuthenticatedUser.allowed_case_types.length
      ) {
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length
        ) {
          const cases = this.getCaseInformation.case_types;
          const casetypes = this.getAuthenticatedUser.allowed_case_types;

          if (cases.every((item) => casetypes.includes(item))) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("cases", [
      "getMedicationCreateStatus",
      "getAllMedications",
      "getMedicationValidationErrors",
    ]),
    ...mapGetters("orders", ["getCaseInformation"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
  },
  mounted() {
    if (this.$route.params.order_id) {
      this.checkCaseInfo();
    }
    if (this.$route.params.order_id && this.medicationsData.length != 0) {
      this.patientMedicationFormData.options = this.medicationsData;
    } else if (this.medicationsData.length != 0) {
      this.patientMedicationFormData.options = this.medicationsData;
    }
  },
  methods: {
    checkCaseInfo() {
      if (this.getCaseInformation.medication) {
        this.patientMedicationFormData.options =
          this.getCaseInformation.medication || [];
      }
    },
    getMedicationsList() {
      if (
        this.patientMedicationFormData.options &&
        this.patientMedicationFormData.options.length > 0
      ) {
        return this.patientMedicationFormData.options;
      } else {
        return this.patientMedicationFormData.options;
      }
    },
    async getMedicationsData() {
      try {
        this.loading = true;
        await this.$store.dispatch("cases/fetchAllMedicationsData", {
          get_all: true,
        });
        this.patientMedicationFormData.medications = this.getAllMedications;
        let groupData = this.getAllMedications
          .filter((medication) => {
            if (!medication.is_child) {
              return medication.drug;
            }
          })
          .map((medication) => medication.drug);
        this.patientMedicationFormData.groupOptions = [...new Set(groupData)];
        this.loading = false;
      } catch (err) {
        this.medicationError = err;
        this.loading = false;
      }
    },

    async createMedication() {
      try {
        this.buttonLoading = true;
        this.medicationError = "";
        await this.$store.dispatch("cases/createMedicationData", this.formData);
        this.buttonLoading = false;
        if (this.getMedicationCreateStatus) {
          successNotification("Medication Created Successfully");
          this.addMedicationModelPopup = false;
          this.lookupDialogVisible = false;
          this.loading = true;

          this.patientMedicationFormData.options.push(
            this.formData.drug.toUpperCase()
          );

          this.formData.group = "";
          this.formData.drug = "";

          await this.getMedicationsData();

          this.formData = {
            drug: "",
            group: "",
            is_child: true,
          };
          this.loading = false;
        }
      } catch (err) {
        this.medicationError = err;
        this.buttonLoading = false;
      }
    },
    removeMedication(medication) {
      let testName = medication.split("_").join(" ");
      this.$confirm(
        `Are you sure want to remove this
        <br/>
        <b style="color:#0A5DA6">"${testName}"</b> medication ?`,
        "Warning",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        const index =
          this.patientMedicationFormData.options.indexOf(medication);
        if (index > -1) {
          this.patientMedicationFormData.options.splice(index, 1);
        }
      });
    },

    addMedication() {
      if (
        this.patientMedicationFormData.value.trim().length &&
        this.patientMedicationFormData.options.indexOf(
          this.patientMedicationFormData.value.trim()
        ) == -1
      ) {
        this.patientMedicationFormData.options.push(
          this.patientMedicationFormData.value.trim()
        );
      }
      this.patientMedicationFormData.medicationsData = [];
      this.patientMedicationFormData.value = "";
    },
    async getAutoCompleteMedicationsData(queryString) {
      try {
        this.medicationLoading = true;
        if (queryString && queryString.length > 1) {
          await this.$store.dispatch("cases/fetchAllMedicationsData", {
            get_all: true,
            search_string: queryString,
          });
          this.patientMedicationFormData.medicationsData =
            this.getAllMedications;
        } else {
          this.patientMedicationFormData.medicationsData = [];
        }
        this.medicationLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    removeErrors() {
      this.formData = {};
      this.$store.commit(
        "cases/setMedicationValidationErrors",
        {},
        { root: true }
      );
    },
  },
};
</script>
